import { Box } from '@chakra-ui/react';
import { InnerDrawer } from '@pluxee-design-system/react';
import { getPriceAndFees } from 'components/Products/PayoutCard/utils';
import useDrawerPosition from 'data/useDrawerPosition';
import { PayoutChannel, PayoutFrequency, ProductFull, ProductTypeEnum } from 'generated/models';
import useTranslations from 'i18n';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import gtm from 'trackers/gtm';
import LocationsFooter from '../LocationsFooter';
import { ChangeLocations, CommonProduct, PartnerAccountsDict, PayoutsFormValues } from '../types';
import LocationStep from './LocationStep';
import PayoutsStep from './PayoutsStep';
import { getSelectedPayout } from './utils';

interface ProductDrawerProps {
  // permissions
  isLocationsStepEditable?: boolean; // can edit assigned locations via LocationStep?
  isPayoutsStepEditable?: boolean; // can edit payout via PayoutsStep?
  isProductDetachable?: boolean; // can remove product?
  withPayouts?: boolean; // visibility of PayoutsStep
  withLocations?: boolean; // visibility of LocationStep
  withTracking?: boolean; // track events
  // rest
  product: CommonProduct;
  partnerAccounts: PartnerAccountsDict;
  removeText?: string;
  selectedPayoutId?: string;
  onRemove?: (product: CommonProduct) => void;
  onSelect: (
    product: CommonProduct,
    payout?: PayoutChannel,
    locations?: ChangeLocations,
    onlyLocations?: boolean,
    accountNumber?: string,
  ) => void;
  onClose: () => void;
  locationCount?: number;
}

enum ViewTypeEnum {
  Payouts = 'payouts',
  Locations = 'locations',
}

const ProductDrawer = ({
  isLocationsStepEditable = true,
  isPayoutsStepEditable = true,
  isProductDetachable = true,
  product,
  product: { code: productId, nameKey: productNameKey },
  partnerAccounts,
  removeText,
  selectedPayoutId,
  onClose,
  onRemove,
  onSelect,
  locationCount = 0,
  withPayouts = true,
  withLocations = false,
  withTracking = false,
}: ProductDrawerProps) => {
  const { t } = useTranslations();
  // hack to offset Drawer based on visibility of Header
  const wrapperRef = useRef<HTMLDivElement>(null);
  useDrawerPosition(wrapperRef);

  const payoutList = (product as ProductFull)?.payoutList;
  const payouts = useMemo(
    () =>
      (payoutList || []).reduce((result, current) => {
        result[String(current.nameKey)] = current;
        return result;
      }, {} as Record<string, PayoutFrequency>),
    [payoutList],
  );
  const [selectedPayout, setSelectedPayout] = useState(() =>
    getSelectedPayout(payoutList, selectedPayoutId),
  );
  // TODO: somehow initialize based on selected product if defined
  const [selectedLocationCount, setSelectedLocationCount] = useState<number>(locationCount);
  const [selectedLocations, setSelectedLocations] = useState<ChangeLocations | undefined>(
    undefined,
  );
  const [viewType, setViewType] = useState(
    withPayouts ? ViewTypeEnum.Payouts : ViewTypeEnum.Locations,
  );
  const onShowPayouts = useCallback(() => setViewType(ViewTypeEnum.Payouts), [setViewType]);
  const onShowLocations = useCallback(() => setViewType(ViewTypeEnum.Locations), [setViewType]);
  const onRemoveProduct = useMemo(
    () => (onRemove ? () => onRemove(product) : undefined),
    [onRemove, product],
  );

  const handleSelectPayout = useCallback(
    (newPayout: PayoutChannel) => {
      if (withTracking && newPayout) {
        gtm.trackClickProductOfferSelect(
          t(`product_details.products.*.${product.nameKey}.name`, product.name),
          getPriceAndFees(newPayout.priceList),
          newPayout.frequencyIndividual
            ? t('product_details.payoutFrequency.individual', 'Individual')
            : t(
                `product_details.payoutFrequency.${newPayout.frequencyNameKey}`,
                newPayout.frequencyName,
              ),
          t(`product_details.payouts.*.${newPayout.nameKey}.name`, newPayout.name),
          t(
            `product_details.products.*.${product.nameKey}.tiers.${newPayout.priceTierNameKey}`,
            newPayout.priceTierName,
          ),
        );
      }
      setSelectedPayout(newPayout);
    },
    [product.name, product.nameKey, setSelectedPayout, t, withTracking],
  );

  const handlePayoutsSubmit = useCallback(
    (values: PayoutsFormValues) => {
      onSelect(
        product,
        selectedPayout,
        selectedLocations,
        !isPayoutsStepEditable,
        values.partnerAccounts[values.parameterCode] ?? undefined,
      );
      onClose();
    },
    [isPayoutsStepEditable, onClose, onSelect, product, selectedLocations, selectedPayout],
  );
  const handleLocationsSubmit = useCallback(
    (toAdd: string[], toRemove: string[]) => {
      setSelectedLocations({ toAdd, toRemove });
      setSelectedLocationCount(locationCount + toAdd.length - toRemove.length);
      setViewType(ViewTypeEnum.Payouts);
    },
    [locationCount],
  );
  const handleOnlyLocationsSubmit = useCallback(
    (toAdd: string[], toRemove: string[]) => {
      onSelect(product, undefined, { toAdd, toRemove }, true, undefined);
      onClose();
    },
    [onClose, onSelect, product],
  );

  useEffect(() => {
    setSelectedPayout(getSelectedPayout(payoutList, selectedPayoutId));
  }, [payoutList, selectedPayoutId, setSelectedPayout]);

  useEffect(() => {
    setViewType(withPayouts ? ViewTypeEnum.Payouts : ViewTypeEnum.Locations);
  }, [setViewType, productId, withPayouts]);

  useEffect(() => {
    setSelectedLocationCount(locationCount);
    setSelectedLocations(undefined);
  }, [productId, locationCount, setSelectedLocationCount, setSelectedLocations]);

  return (
    <Box ref={wrapperRef} position="fixed" right={0} left={0}>
      <InnerDrawer
        title={{ text: t(`product_details.products.*.${product.nameKey}.name`, product.name) }}
        isOpen={true}
        onClose={onClose}
      >
        {viewType === ViewTypeEnum.Payouts && withPayouts && (
          <PayoutsStep
            isEditable={isPayoutsStepEditable}
            isSubmittable={isPayoutsStepEditable || (isLocationsStepEditable && withLocations)}
            isStepValid={
              isPayoutsStepEditable ? selectedPayout !== undefined : isLocationsStepEditable
            }
            onSelectPayout={handleSelectPayout}
            onSubmit={handlePayoutsSubmit}
            onShowLocations={onShowLocations}
            payouts={payouts}
            partnerAccounts={partnerAccounts}
            productNameKey={productNameKey}
            selectedPayout={selectedPayout}
            showWarning={product.type === ProductTypeEnum.Paper}
            withLocations={withLocations}
          >
            <LocationsFooter
              removeProductText={removeText}
              selectedLocations={selectedLocationCount}
              onRemoveProduct={isProductDetachable ? onRemoveProduct : undefined}
              onShowLocations={withLocations ? onShowLocations : undefined}
            />
          </PayoutsStep>
        )}
        {viewType === ViewTypeEnum.Locations && withLocations && (
          <LocationStep
            isEditable={isLocationsStepEditable}
            selectedLocations={selectedLocations}
            productId={productId}
            onBack={withPayouts ? onShowPayouts : undefined}
            onSubmit={withPayouts ? handleLocationsSubmit : handleOnlyLocationsSubmit}
          />
        )}
      </InnerDrawer>
    </Box>
  );
};

export default ProductDrawer;
