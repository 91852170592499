import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { Icons, SvgSpriteConsumer, Tooltip } from '@pluxee-design-system/react';
import { formatPrice, getPriceAndFees } from 'components/Products/PayoutCard/utils';
import useConfig from 'data/useConfig';
import {
  PayoutChannel,
  PayoutChannelSelected,
  Product,
  ProductFull,
  ProductTypeEnum,
} from 'generated/models';
import useTranslations from 'i18n';

interface SelectedPayoutInfoProps {
  payout: PayoutChannel | PayoutChannelSelected;
  product: ProductFull | Product;
  variant?: 'default' | 'dashboard';
}

const SelectedPayoutInfo = ({ payout, product, variant = 'default' }: SelectedPayoutInfoProps) => {
  const { isoCode } = useConfig();
  const { t } = useTranslations();

  if (isoCode === 'be') {
    const tKey = `product_details.products.*.${product.nameKey}.tiers.${payout.priceTierNameKey}`;

    return (
      <Box background="core.blue.1" p={2}>
        <Text variant="body.largeXbold">{t(`${tKey}.name`, payout.priceTierName)}</Text>
        <Text variant="body.smallMedium">{t(`${tKey}.description`, payout.priceTierName)}</Text>
      </Box>
    );
  }

  if (product.type === ProductTypeEnum.Paper) {
    return (
      <Box
        {...(variant === 'dashboard'
          ? { textStyle: 'body.medium' }
          : { bgColor: 'core.blue.1', p: 2, textStyle: 'body.smallMedium' })}
      >
        {t(
          'product_details.prices.voucherPricesNote',
          'Prices for vouchers are determined by the mean how the vouchers are delivered to Pluxee and on the requested payout method for each remittance.',
        )}
      </Box>
    );
  }

  const payoutName = t(`product_details.payouts.*.${payout.nameKey}.name`, payout.name);
  const payoutFrequency = payout.frequencyIndividual
    ? t('product_details.payoutFrequency.individual', 'Individual')
    : t(`product_details.payoutFrequency.${payout.frequencyNameKey}`, payout.frequencyName);

  if (variant === 'dashboard') {
    return (
      <>
        <Box>
          <Text variant="body.largeBold">{payoutName}</Text>
          <Text variant="body.medium">
            {t('product_details.prices.payoutChannel', 'Payout channel')}
          </Text>
        </Box>
        <Box>
          <Text variant="body.largeBold">{payoutFrequency}</Text>
          <Text variant="body.medium">
            {t('product_details.prices.payoutFrequency', 'Payout frequency')}
          </Text>
        </Box>
      </>
    );
  }

  const { price, fees } = getPriceAndFees(payout.priceList);
  return (
    <Box background="core.blue.1" p={2}>
      <Flex align="center" width="100%">
        <Text variant="body.largeXbold">{payoutName}</Text>
        <Spacer />
        <Text variant="body.largeXbold" color="semantic.text.highlight">
          {formatPrice(price)}
        </Text>
      </Flex>
      <Flex align="center" width="100%">
        <Text variant="body.smallMedium">{payoutFrequency}</Text>
        <Spacer />
        <Text variant="body.smallMedium">
          {t('product_details.feeTypes.perTransaction', 'Per transaction')}
        </Text>
      </Flex>
      <Flex align="center" width="100%">
        <Flex as={Text} variant="body.smallMedium" gap={1} align="center">
          {t('product_details.prices.transactionFee', 'Transaction fee')}
          <Tooltip
            hasArrow
            label={
              <>
                <p>
                  {t('product_details.prices.transactionFeeInfo', 'Transaction fee when done by:')}
                </p>
                <p>
                  {fees
                    .map(({ feeCode }) => t(`product_details.feeTypes.${feeCode}`, feeCode))
                    .join(' / ')}
                </p>
              </>
            }
          >
            <SvgSpriteConsumer key="question" size={16} svgId={Icons.QUESTION_FILLED16} />
          </Tooltip>
        </Flex>
        <Spacer />
        <Text variant="body.smallMedium">{fees.map((f) => formatPrice(f)).join(' / ')} </Text>
      </Flex>
    </Box>
  );
};

export default SelectedPayoutInfo;
