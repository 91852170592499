import { Checkbox } from '@pluxee-design-system/react';
import { useFormikContext } from 'formik';
import { LocationItem } from 'generated/models';
import { ComponentProps } from 'components/VirtualizedTable/types';
import { ChangeEvent, useCallback } from 'react';
import { get } from 'lodash';

const LocationAccessCell = ({
  row: { editable, location, productStatus },
  extra: isEditable,
}: ComponentProps<LocationItem, boolean, boolean>) => {
  const { setFieldValue, handleChange, values } = useFormikContext();
  const index = location.uid;
  const hasAssignedProduct = Boolean(productStatus);
  const currentValue = get(values, `locations.${index}.newState`, hasAssignedProduct);

  const handleOnChange = useCallback(
    (event: ChangeEvent<any>) => {
      handleChange(event);
      setFieldValue(
        `locations.${index}`,
        {
          oldState: hasAssignedProduct,
          newState: event.target.checked,
        },
        false,
      );
    },
    [handleChange, hasAssignedProduct, index, setFieldValue],
  );

  return (
    <Checkbox
      name={`locations.${index}.newState`}
      isDisabled={!editable || !isEditable}
      id={`locations.${index}`}
      isChecked={currentValue}
      onChange={handleOnChange}
    />
  );
};

export default LocationAccessCell;
