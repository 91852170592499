import { Box, Text, VStack } from '@chakra-ui/react';
import {
  Button,
  ButtonVariant,
  Icons,
  IconButton,
  SvgSpriteConsumer,
} from '@pluxee-design-system/react';
import { useContractProductLocations } from 'api/contractApi';
import { LocationStatusNameCell } from 'components/Employees/CellRenderers';
import { AssignedLocationState } from 'components/Employees/types';
import { ProductLocationFilterValues } from 'components/Form/LocationSelect/types';
import { initialValues as initialFilterValues } from 'components/Form/LocationSelect/utils';
import { AddressCell, IdCell } from 'components/Locations/CellRenderers';
import LocationProductCell from 'components/Products/CellRenderers/LocationProductCell';
import VirtualizedTable, { ColumnType } from 'components/VirtualizedTable';
import useCurrentContract from 'data/useCurrentContract';
import { Form, Formik } from 'formik';
import { ProductLocationsSortingEnum } from 'generated';
import useTranslations from 'i18n';
import { useCallback, useMemo, useState } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { ChangeLocations } from '../types';

interface FormValues {
  locations: Record<string, AssignedLocationState>;
}

interface LocationStepProps {
  isEditable: boolean;
  productId: string;
  onBack?: () => void;
  onSubmit: (toAdd: string[], toRemove: string[]) => void;
  selectedLocations?: ChangeLocations;
}

const getInitialValues = (selectedLocations?: ChangeLocations) => {
  const result: Record<string, AssignedLocationState> = {};

  (selectedLocations?.toAdd || []).forEach((key) => {
    if (!result[key]) {
      result[key] = { oldState: undefined, newState: true };
    }
    result[key].newState = true;
  });

  (selectedLocations?.toRemove || []).forEach((key) => {
    if (!result[key]) {
      result[key] = { oldState: undefined, newState: false };
    }
    result[key].newState = false;
  });

  return result;
};

const LocationStep = ({
  isEditable,
  productId,
  onBack,
  onSubmit,
  selectedLocations,
}: LocationStepProps) => {
  const { t } = useTranslations();
  const { contractId } = useCurrentContract();
  const [filterState, setFilterState] = useState<ProductLocationFilterValues>(initialFilterValues);
  const updateFilterState = useCallback(
    async (newState: ProductLocationFilterValues) => setFilterState(newState),
    [setFilterState],
  );
  const {
    data: locations,
    hasNextPage,
    isEmpty,
    isLoadingPage,
    loadNextPage,
  } = useContractProductLocations(contractId, productId, false, filterState);

  const columns = useMemo<ColumnType[]>(
    () => [
      {
        name: '',
        id: 'select',
        component: LocationProductCell,
        size: 14,
        sortable: false,
        align: 'center',
        extra: isEditable,
      },
      {
        name: t('locations.table.name', 'Name'),
        id: 'location.name',
        component: LocationStatusNameCell,
        sortId: ProductLocationsSortingEnum.Name,
        sortable: true,
      },
      {
        name: t('locations.table.tableId', 'ID'),
        id: 'location.masterId',
        component: IdCell,
        sortId: ProductLocationsSortingEnum.MasterId,
        sortable: true,
      },
      {
        name: t('locations.table.street', 'Street'),
        id: 'location.address.street',
        component: AddressCell,
        sortId: ProductLocationsSortingEnum.Street,
        sortable: true,
      },
      {
        name: t('locations.table.city', 'City'),
        id: 'location.address.city',
        sortId: ProductLocationsSortingEnum.City,
        sortable: true,
      },
    ],
    [isEditable, t],
  );

  const initialValues = useMemo<FormValues>(
    () => ({ locations: getInitialValues(selectedLocations) }),
    [selectedLocations],
  );

  const handleSubmit = useCallback(
    ({ locations: assignedLocations }: FormValues) => {
      const toAdd: string[] = [];
      const toRemove: string[] = [];

      Object.keys(assignedLocations).forEach((key) => {
        if (assignedLocations[key].oldState !== assignedLocations[key].newState) {
          if (assignedLocations[key].newState) {
            toAdd.push(key);
          } else {
            toRemove.push(key);
          }
        }
      });
      onSubmit(toAdd, toRemove);
    },
    [onSubmit],
  );

  return (
    <Formik initialValues={initialValues} enableReinitialize onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <>
          {onBack && (
            <Box position="absolute" top={6}>
              <IconButton
                aria-label={t('global_texts.sidebarNavigation.back', 'Back')}
                variant={ButtonVariant.ICON}
                icon={<SvgSpriteConsumer svgId={Icons.ARROW_LEFT24} size={24} />}
                onClick={onBack}
              />
            </Box>
          )}
          <Box>
            <Text variant="title.desktopCard" mt={10}>
              {t(
                'products_portal.sidebar_texts.locationsHeader',
                'Choose locations for your product',
              )}
            </Text>
            <Box as={Form} mt={6} minHeight="550px">
              <AutoSizer disableWidth defaultHeight={550}>
                {({ height }: Size) => (
                  <VirtualizedTable
                    columns={columns}
                    filterState={filterState}
                    hasNextPage={hasNextPage}
                    isEmpty={isEmpty}
                    isNextPageLoading={isLoadingPage}
                    loadNextPage={loadNextPage}
                    items={locations}
                    itemSize={73}
                    height={height}
                    onSortBy={updateFilterState}
                    pageSize={20}
                    reachedEndText={t('global_texts.labels.reachedEnd', 'Displayed all results')}
                  />
                )}
              </AutoSizer>
            </Box>
          </Box>
          {isEditable && (
            <VStack
              position="sticky"
              spacing={6}
              bottom={0}
              background="semantic.surface.1"
              py={6}
              mb={-6}
            >
              <Button
                onClick={submitForm}
                type="button"
                variant="primaryFilled"
                id="LocationsStep_applyButton"
              >
                {t('products.sidebarButtons.apply', 'Apply')}
              </Button>
            </VStack>
          )}
        </>
      )}
    </Formik>
  );
};

export default LocationStep;
