import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { IconButton, SvgSpriteConsumer } from '@pluxee-design-system/react';
import { LocationTypeEnum } from 'generated';
import useTranslations from 'i18n';
import { useCallback, useMemo, useState } from 'react';
import OnlineFees from './BeOnlineFees';
import PhysicalFees from './BePhysicalFees';
import { CountryPayoutCardProps } from './types';
import { getPriceAndFees } from './utils';

const BePayoutCard = ({
  channel: { priceTierName, priceTierNameKey, priceList },
  children,
  locationType,
  onlyProductName = false,
  productName,
  productNameKey,
}: CountryPayoutCardProps) => {
  const { t } = useTranslations();
  const [isExpanded, setExpanded] = useState(true);
  const toggleExpanded = useCallback(() => setExpanded((p) => !p), [setExpanded]);
  const fees = useMemo(() => getPriceAndFees(priceList), [priceList]);
  const tKey = `product_details.products.*.${productNameKey}.tiers.${priceTierNameKey}`;
  const showOnlineFees =
    locationType === undefined ||
    locationType === LocationTypeEnum.Online ||
    locationType === LocationTypeEnum.Marketplace;
  const showPhysicalFees = locationType === undefined || locationType === LocationTypeEnum.Physical;

  return (
    <Box width="100%">
      <Flex gap={4} justify="space-between">
        <Box>
          <Text as="div" variant="subtitle.section">
            {onlyProductName
              ? t(`product_details.products.*.${productNameKey}.name`, productName)
              : t(`${tKey}.name`, priceTierName)}
          </Text>
          <Text as="div" variant="subtitle.card" mt={1.5}>
            {t(`${tKey}.description`, priceTierName)}
          </Text>
        </Box>
        <Box>
          <IconButton
            aria-label="chevron"
            icon={
              <SvgSpriteConsumer size={24} svgId={isExpanded ? 'chevronUp24' : 'chevronDown24'} />
            }
            onClick={toggleExpanded}
            size="md"
            variant="icon"
          />
        </Box>
      </Flex>
      {isExpanded && (
        <VStack align="stretch" spacing={2} mt={4}>
          <HStack spacing={6} align="flex-start">
            {showPhysicalFees && <PhysicalFees fees={fees} t={t} />}
            {showOnlineFees && <OnlineFees fees={fees} t={t} />}
          </HStack>
          {children}
        </VStack>
      )}
    </Box>
  );
};

export default BePayoutCard;
