import { Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Button, Tag } from '@pluxee-design-system/react';
import ProductHeader from 'components/Products/ProductHeader';
import { Product, ProductFull, ProductStatusEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { ReactNode } from 'react';

interface ProductCardProps extends I18nProps {
  children?: ReactNode;
  product: Product | ProductFull;
  isEditable?: boolean;
  isSelected: boolean;
  isInBasket: boolean;
  onSelect?: () => void;
  forceEdit?: boolean;
  status?: ProductStatusEnum;
  withDescription?: boolean;
}

const ProductCard = ({
  children,
  product,
  isEditable = true,
  isSelected,
  isInBasket,
  onSelect,
  forceEdit = false,
  status,
  withDescription = false,
  t,
  tHtml,
}: ProductCardProps) => (
  <Flex direction="column" flex="1" boxShadow="medium" width={317} bgColor="semantic.surface.1">
    <ProductHeader product={product} status={status} />
    <Flex p={4} gap={6} direction="column" height="100%">
      <VStack as="header" spacing={4} align="stretch">
        <Text as="h3" variant="title.desktopCard">
          {t(`product_details.products.*.${product.nameKey}.name`, product.name)}
        </Text>
        {product.type && (
          <HStack spacing={2}>
            <Tag size="sm">{t(`product_details.mediaTypes.${product.type}`, product.type)}</Tag>
          </HStack>
        )}
        {withDescription && (
          <Text as="div" variant="body.largeMedium" color="semantic.text.secondary">
            {tHtml(`product_details.products.*.${product.nameKey}.description`, product.name)}
          </Text>
        )}
        {children}
      </VStack>
      {onSelect && (
        <Button
          type="button"
          onClick={onSelect}
          variant={isInBasket || isSelected ? 'secondaryFilled' : 'secondaryOutlined'}
          id="ProductCard_offerButton"
          size="md"
          width="100%"
          mt="auto"
        >
          {isInBasket
            ? t('global_texts.buttons.edit', 'Edit')
            : isEditable && (isSelected || forceEdit)
              ? t('global_texts.buttons.edit', 'Edit')
              : t('products.buttons.seeOffer', 'See offer')}
        </Button>
      )}
    </Flex>
  </Flex>
);

export default withTranslations(ProductCard);
