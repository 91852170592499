import {
  Icons,
  InputVariant,
  Radio,
  SvgSpriteConsumer,
  Tooltip,
} from '@pluxee-design-system/react';
import ProductCard from 'common/cards/ProductCard';
import InputControl from 'common/forms/InputControl';
import useConfig from 'data/useConfig';
import { PayoutChannel } from 'generated/models';
import useTranslations from 'i18n';
import BePayoutCard from './BePayoutCard';
import CzPayoutCard from './CzPayoutCard';

interface PayoutCardProps {
  hasError?: boolean;
  channel: PayoutChannel;
  isSelected: boolean;
  isDisabled?: boolean;
  productNameKey?: string;
}

const PayoutCard = ({
  hasError,
  channel,
  channel: { nameKey, parameterCode, payoutUid },
  isSelected,
  isDisabled = false,
  productNameKey,
}: PayoutCardProps) => {
  const { isoCode } = useConfig();
  const { t } = useTranslations();
  const variant =
    isSelected && hasError ? InputVariant.ERROR : isSelected ? 'selected' : InputVariant.FILLED;
  const PayoutCardComponent = isoCode === 'be' ? BePayoutCard : CzPayoutCard;

  return (
    <ProductCard variant={variant}>
      <Radio value={payoutUid ?? undefined} isDisabled={isDisabled} />
      <PayoutCardComponent channel={channel} productNameKey={productNameKey}>
        {parameterCode && (
          <InputControl
            type="text"
            mandatory
            name={`partnerAccounts.${parameterCode}`}
            id={payoutUid ?? undefined}
            placeholder={t(`product_details.payouts.*.${nameKey}.partnerAccount`, parameterCode)}
            title={t(`product_details.payouts.*.${nameKey}.partnerAccount`, parameterCode)}
            disabled={isDisabled}
            rightElement={
              <Tooltip
                hasArrow
                label={t(
                  `product_details.partnerAccounts.*.${nameKey}.helpNote`,
                  'Account number identifier',
                )}
              >
                <SvgSpriteConsumer key="icon1" size={24} svgId={Icons.QUESTION24} />
              </Tooltip>
            }
            // TODO: icon overflows, use sm when fixed
            // size="sm"
          />
        )}
      </PayoutCardComponent>
    </ProductCard>
  );
};

export default PayoutCard;
